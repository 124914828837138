import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../../App.css';
import './SignUpPage.css';
import Button from '../Button.js';

import { useNavigate } from 'react-router-dom'

import wallpaper from '../images/FruitShare_Login_Background.png';

function SignUpPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const baseURL = 'https://fruit-share-a392364d7985.herokuapp.com'

  const handleAccountSubmit = async (email) => {
    try {
      const response = await axios.get(`${baseURL}/api/checkForUser/${email}`);
      const data = response.data;
      if(data) {
        alert("Account with this email already exists");
      } else {
        createNewAccount(email);
      }
    } catch (error) {
      console.log(error.message);
      alert("There was an error creating your account, please try again in a moment");
    }
  }

  const createNewAccount = async (email) => {
    try {
      await axios.post(`${baseURL}/api/addNewUser`, {
        "email": email.toString()
      });

      localStorage.setItem('userEmail', email.toString());
      alert("Account created successfully!");
      window.location.reload();
      navigate('/')
    } catch (error) {
      console.log(error.message);
    }
  }
    
  return (
    <div className='parentdiv'>
      <img className='wallpaper' alt="fruit background" src={wallpaper}></img>
      <div className='signUpContainer'>
        <h1>Create a FruitShare Account</h1>
        <div className='inputElementsParent'>
          <div className='inputElements'>
            <h4>Email:</h4>
            <input className='inputfield' id="email" value={email} onChange={(e) => setEmail(e.target.value)} type="text"/>
            <br/>
            <div className='signupButtons'>
              <Button className='variant1' onClick={() => handleAccountSubmit(email)}>Sign Up</Button>
              <Link to='/LoginPage'>Already have an account?</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUpPage;
