import React from 'react'
import "../pages/VolunteerResources.css";

import PickingPDF from '../PDFs/PickingGuide.pdf';
import NextdoorPostPDF from '../PDFs/NextdoorPosts.pdf';

function VolunteerResources() {
  return (
    <>
        <br />
        <br />
        <center><h2>Volunteer Resources</h2></center>

        <div className="contentContainer">
            <div className="VolunteerResourcesTOC">
                <h4>Picking Guide</h4>
                <h4> | </h4>
                <h4>Nextdoor Post Guide</h4>
            </div>

            <h3>🍋 Picking Guide</h3>
            <div className='PDFContainer'>
                <object data={PickingPDF} type="application/pdf" className="PDF">
                    <p>Picking Guide for Volunteers</p>
                </object>
            </div>

            <br />
            <h3>📝 Nextdoor Post Guide</h3>
            <div className='PDFContainer'>
                <object data={NextdoorPostPDF} type="application/pdf" className="PDF">
                    <p>Nextdoor Post Guide for Volunteers</p>
                </object>
            </div>
        </div>
    </>
  )
}

export default VolunteerResources