import React, { useState, useEffect, useRef } from 'react';
import '../../App.css'
import './Home.css'
import vsketch from '../images/volunteersketch.jpg'
import fsketch from '../images/fruitsketch.jpg'
//import hsketch from '../images/homesketch.jpg'
import hsketchnew from '../images/hsketchnew.png'
import Button from '../Button'
import { Link } from 'react-router-dom'
import CountUp from 'react-countup'
import Card from 'react-bootstrap/Card'
import Carousel from '../Carousel'

import Infographic from '../Infographic';

import './About.css'
import banner from '../images/aboutUsBanner.png'

import Typewriter from "typewriter-effect";

import homepageBannerv2 from '../images/AppleBannerv6.png'

import Feedback from '../Feedback';
import TransitionText from '../TransitionText';

import 'react-tooltip/dist/react-tooltip.css'

import AdiHeadshot from '../images/headshots/AdiHeadshot.jpg';
import ArshHeadshot from '../images/headshots/ArshHeadshot.jpg';
import AryanHeadshot from '../images/headshots/AryanHeadshot.jpg';
import AravHeadshot from '../images/headshots/AravHeadshot.jpg';
import AishaniHeadshot from '../images/headshots/AishaniHeadshot.jpg'


function Home () {
    const [show, setShow] = useState(3);

    const handleResize = () => {
        if (window.innerWidth < 700) {
            setShow(1);
        } else if(window.innerWidth < 1000) {
            setShow(2);
        } else {
            setShow(3);
        }
    };

    useEffect(() => {
        handleResize(); // Set initial value
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    
    const elementRef = useRef(null); // Ref for visibility check
    const cardRefs = useRef([]);
    useEffect(() => {
        const handleScroll = () => {
            if (elementRef.current) {
                const rect = elementRef.current.getBoundingClientRect();
                const isVisible = (
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                );
                if (isVisible) {
                    cardRefs.current.forEach((card, index) => {
                        if (card) {
                            card.classList.add('animate');
                            card.style.animationDelay = `${index * 0.15}s`;
                        }
                    });
                }
            }
        };
    
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // Initial check on component mount
    
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            <div className='bodyContainer'>
                <div className='columnLeft'>
                    <h1>
                    <Typewriter
                        onInit={(typewriter) => {
                        typewriter
                            .typeString("We don't let any fruits go to waste.")
                            .pauseFor(1000) // Optional pause after typing is complete
                            .start();
                        }}
                        options={{
                        delay: 40,
                        cursor: '',
                        loop: false,

                        }}
                    />
                    </h1>
                    <h4 className='subText'>Join us in our mission to pick extra fruits from local homeowners and deliver them to communities, and charities. Together, we reduce food waste and support those who can benefit from fresh, healthy produce.</h4>

                    
                    
                    <Link to='/Donations?request=new'>
                        <Button className='variant1'>Donate Fruits</Button>
                    </Link>
                    
                    <Link target="_blank" to='https://www.wvcommunityservices.org/'>
                        <Button className='variant2'>Where we donate</Button>
                    </Link>
                    
                </div>
                
                <div className='columnRight'>
                    <img className="mainImage" src = {homepageBannerv2} alt="Cool"></img>
                </div>
            </div>
            <center>
                <div className = 'stats'>
                    <div className = 'volunteerStats'>
                        <img className="vimage" src = {vsketch} alt="Volunteer"></img>
                        <h2><CountUp end = {11} duration={3} className = "vstats"></CountUp></h2>
                        <h4 className = 'volunteerss'>Volunteers</h4>
                    </div>
                    <div className = 'fruitStats'>
                        <img className="fimage" src = {fsketch} alt="Fruit"></img>
                        <h2><CountUp end = {3220} duration={3}></CountUp></h2>
                        <h4 className = 'volunteerss'>Lbs of fruit donated</h4>
                    </div>
                    <div className = 'homeownersStats'>
                        <br></br>
                        <br></br>
                        <img className="himage" src = {hsketchnew} alt="Home"></img>
                        
                        <br></br>
                        <br></br>
                        <h2><CountUp end = {41} duration={3}></CountUp></h2>
                        <h4 className = 'volunteerss'>Homeowners</h4>
                    </div>
                </div>
            </center>
            
            <br />
            <br />
            <br />
            <br />
            <br />

            <center>
            <div className='feedbackBody'>
                <center><TransitionText text="What Homeowners Have to Say" Tag="h2" className='header80'/></center>
                <Link to='/Feedback'><h4 className='readAllBlogsAndsResources'>Give Feedback</h4></Link>
                
                <br />
                <Feedback />
            </div>
            </center>
            
            <br />
            <br />
            <br />
            <br />
            <center>
            <h2>Resources and Blogs</h2>
            <Link to='/Blogs'><h4 className='readAllBlogsAndsResources'>Read All</h4></Link>
            <br></br>
            <br></br>
            
                <div className = "blogs" ref={elementRef}>
                    {/* modified carousel component: can hold multiple items in front */}
                    {/* Tips and Guides */}
                    <Carousel className='animationHook' show={show}>
                        <div>
                            <Card className = 'fade1 fadecard' ref={(el) => cardRefs.current[0] = el} style={{ width: '18rem', animationDelay: '0.2s' }}>
                                <Card.Body>
                                    <Card.Title>Get Started: Homeowners</Card.Title>
                                    <Card.Text>
                                    If you would like to play a part in reducing food waste, click here to learn about how you can contribute. 
                                    </Card.Text>
                                    <Link to='/Homeowners'>
                                        <Button className="variant1">Read More</Button>
                                        </Link>
                                </Card.Body>
                            </Card>
                        </div>
                    
                        <div>
                            <Card className = 'fade2 fadecard' ref={(el) => cardRefs.current[1] = el} style={{ width: '18rem', animationDelay: '0.4s' }}>
                                <Card.Body>
                                    <Card.Title>Get Started: Volunteers</Card.Title>
                                    <Card.Text>
                                    Learn about what it means to be a volunteer and how you can get started helping your community.
                                    </Card.Text>
                                    <Link to='/VolunteerResources'>
                                        <Button className="variant1">Read More</Button>
                                    </Link>
                                </Card.Body>
                            </Card>
                        </div>

                        <div>
                            <Card className = 'fade3 fadecard' ref={(el) => cardRefs.current[2] = el} style={{ width: '18rem', animationDelay: '0.6' }}>
                                <Card.Body>
                                    <Card.Title>How to prepare to get your fruits picked</Card.Title>
                                    <Card.Text>
                                    Learn how to make your interaction between our volunteers and your precious trees as productive as possible.
                                    </Card.Text>
                                    <Link to='/Blogs/HelpingOurVolunteers'>
                                        <Button className="variant1">Read More</Button>
                                    </Link>
                                </Card.Body>
                            </Card>
                        </div>

                        <div>
                            <Card style={{ width: '18rem' }}>
                                <Card.Body>
                                    <Card.Title>Become more than a volunteer</Card.Title>
                                    <Card.Text>
                                    If you would like to take your contributions to our initiative even further, check out how you can become a part of our leadership.
                                    </Card.Text>
                                    <Link to='/Blogs/GetLeadership'>
                                        <Button className="variant1">Read More</Button>
                                    </Link>
                                </Card.Body>
                            </Card>
                        </div>
                    
                    </Carousel>

                    
                </div>
                <div className = "blogs">
                    {/* modified carousel component: can hold multiple items in front */}
                    {/* Blog Content */}
                    <Carousel show={show}>
                    <div>
                        <Card className = 'fade6 fadecard' ref={(el) => cardRefs.current[6] = el} style={{ width: '18rem', animationDelay: '1.2s' }}>
                            <Card.Body>
                                <Card.Title>Our favorite fruits and why you should grow them</Card.Title>
                                <Card.Text>
                                Learn more about the kinds of fruits we love collecting from homeowners and how you 
                                can play a part in producing donation-friendly fruit.
                                </Card.Text>
                                <Link to='/Blogs/FruitsWeLove'>
                                    <Button className="variant1">Read More</Button>
                                    </Link>
                            </Card.Body>
                        </Card> 
                    </div>

                    <div>
                        <Card className = 'fade5 fadecard' ref={(el) => cardRefs.current[5] = el} style={{ width: '18rem', animationDelay: '1s' }}>
                            <Card.Body>
                                <Card.Title>How to revive a garden</Card.Title>
                                <Card.Text>
                                if you have given up hope of fulfilling your gardening dreams, read this short piece to find out how you can renovate your garden.
                                </Card.Text>
                                <Link to='/Blogs/RevitalizeYourGarden'>
                                    <Button className="variant1">Read More</Button>
                                    </Link>
                            </Card.Body>
                        </Card> 
                    </div>
                    
                    <div>
                        <Card className = 'fade4 fadecard' ref={(el) => cardRefs.current[4] = el} style={{ width: '18rem', animationDelay: '0.8s' }}>
                            <Card.Body>
                                <Card.Title>The healthiest fruits no one is growing</Card.Title>
                                <Card.Text>
                                Many popular fruits have great benefits, but these 3 superfruits are surprisingly known by few.
                                </Card.Text>
                                <Link to='/Blogs/Superfruits'>
                                    <Button className="variant1">Read More</Button>
                                    </Link>
                            </Card.Body>
                        </Card> 
                    </div>

                    <div>
                        <Card style={{ width: '18rem' }}>
                            <Card.Body>
                                <Card.Title>The most efficient way to water your plants</Card.Title>
                                <Card.Text>
                                Learn about a science and engineering backed way to hydrate your plants with speed and efficiency.
                                </Card.Text>
                                <Link to='/Blogs/EffectivePlantHydration'>
                                    <Button className="variant1">Read More</Button>
                                    </Link>
                            </Card.Body>
                        </Card> 
                    </div>
                    
                    </Carousel>
                </div>
            </center>

            <br />
            <br />
            <div className='aboutBody'>
            <center>
            <div className='aboutContainer'>
                <div className='blurb'>
                    <Infographic bodyContent={<h1>About Us</h1>}></Infographic>
                    <h4>
                        Hi there! <br /><br />

                        We are a group of high schoolers from the San Francisco Bay Area with an idea to help our community. 
                        Have you ever been walking outside and noticed trees with hundreds of unpicked fruits falling 
                        to the ground? We have, and we’ve realized that many homeowners have more fruits than they 
                        can use. <br /><br />

                        Our mission is to reduce this wastage by providing homeowners with the resources they need to 
                        put their surplus fruits to good use. If you have extra fruits and want to help your community, 
                        register to get your fruits picked and make a difference today. <br /><br />

                        Thanks! <br /><br />

                        <h4 className='signature'>Aditya Jha and Arav Bansal</h4>

                    </h4>
                </div>
                <div className='imgContainer'>
                    <img src={banner} alt='About banner'></img>
                </div>
            </div>
            <TransitionText text="Meet Our Team" Tag="h2" />
            {/* <center><h2>Meet Our Team</h2></center> */}
            </center>

            <center>
            <div className='profiles'>
                <div className='person'>
                    <img alt = "" src={AdiHeadshot}></img>
                    <h3>Aditya Jha</h3>
                    <h4 className='title'>Co-Founder</h4>
                    <h4 className='school'>Junior at Leigh High School</h4>
                </div>
                <div className='person'>
                    <img alt = "" src={AravHeadshot}></img>
                    <h3>Arav Bansal</h3>
                    <h4 className='title'>Co-Founder</h4>
                    <h4 className='school'>Junior at Saint Francis High</h4>
                </div>
                <div className='person'>
                    <img alt = "" src={AryanHeadshot}></img>
                    <h3>Aryan Mohanty</h3>
                    <h4 className='title'>Outreach Lead</h4>
                    <h4 className='school'>Sophomore at Leigh High School</h4>
                </div>
                <div className='person'>
                    <img alt = "" src={ArshHeadshot}></img>
                    <h3>Arsh Bhardwaj</h3>
                    <h4 className='title'>Sunnyvale Rep.</h4>
                    <h4 className='school'>Freshman at Saint Francis High</h4>
                </div>
                <div className='person'>
                    <img alt = "" src={AishaniHeadshot}></img>
                    <h3>Aishani Bansal</h3>
                    <h4 className='title'>Creative Director</h4>
                    <h4 className='school'>Freshman at Saint Francis High</h4>
                </div>
                
            </div>
            </center>
        </div>
            
        </>
    )
}

export default Home;